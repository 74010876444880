import { configureStore } from '@reduxjs/toolkit';
import { jetEngineApi } from './api/jet-engine-api';
import { wordPressApi } from './api/wordpress-api';
import { zapierApi } from './api/zapier-api';
import authReducer from './slices/auth-slice';
import quoteDetailsReducer from './slices/quote-details-slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        quoteDetails: quoteDetailsReducer,
        [wordPressApi.reducerPath]: wordPressApi.reducer,
        [jetEngineApi.reducerPath]: jetEngineApi.reducer,
        [zapierApi.reducerPath]: zapierApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(wordPressApi.middleware)
            .concat(jetEngineApi.middleware)
            .concat(zapierApi.middleware)
});
