// Array Helper
export const objectToArray = (obj) => {
    return (
        obj &&
        Object.keys(obj)?.map((key, index) => ({
            ...obj[key]
        }))
    );
};

export default objectToArray;
