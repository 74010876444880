// React
import React, { useEffect, useState } from 'react';
import SvgIcon from '../../../../common/svg-icon';

// Postcode Helpers
const isFullPostcode = (postcode) => {
    // Add your logic to check if the description is a full postcode
    return /\b[A-Z]{1,2}\d{1,2}[A-Z]?\s*\d[A-Z]{2}\b/.test(postcode);
};

const PostcodeSearch = ({
    basePostcode = 'SG4 0RJ',
    resultSelected,
    setResults
}) => {
    const [input, setInput] = useState(
        resultSelected?.structured_formatting?.main_text || ''
    );

    const handleOnChange = (input) => {
        const { value } = input.target;

        return setInput(value);
    };

    useEffect(() => {
        const options = {
            input,
            types: ['(regions)']
        };

        const AutocompleteService =
            new window.google.maps.places.AutocompleteService();

        AutocompleteService.getPlacePredictions(options, (results, status) => {
            if (status === 'OK') {
                setResults(
                    results.filter((postcode) =>
                        isFullPostcode(
                            postcode?.structured_formatting?.main_text
                        )
                    )
                );
            } else {
                setResults(null);
            }
        });
    }, [input, setResults]);

    return (
        <div className="postcode-search">
            <p className="postcode-search__label">Enter your postcode...</p>

            <div className="postcode-search__input-container">
                <SvgIcon name={'pin'} cssClass="postcode-search__icon" />

                <input
                    className="postcode-search__input"
                    onChange={handleOnChange}
                    value={input}
                    tabIndex={0}
                />
            </div>
        </div>
    );
};

export default PostcodeSearch;
