import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_CONFIG } from './base-api-config';

export const wordPressApi = createApi({
    reducerPath: 'wordpressApi',
    baseQuery: fetchBaseQuery({ baseUrl: API_CONFIG.WORD_PRESS_JSON }),
    endpoints: (builder) => ({
        postQuote: builder.mutation({
            query: (data) => ({
                url: `${API_CONFIG.QUOTE_TOOL_API}/store_quote/`,
                method: 'POST',
                body: data
            })
        }),
        getQuoteById: builder.query({
            query: ({ apiKey, quote_id }) => {
                return {
                    url: `${API_CONFIG.QUOTE_TOOL_API}/get_quote/`,
                    headers: {
                        Authorization: `Bearer ${apiKey}`
                    },
                    params: { quote_id }
                };
            }
        })
    })
});

export const { usePostQuoteMutation, useGetQuoteByIdQuery } = wordPressApi;
