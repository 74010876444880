// React / Redux
import React, { useCallback } from 'react';

// Components JSX
import SvgIcon from '../../../common/svg-icon';
import { useDispatch, useSelector } from 'react-redux';
import { setQuoteDetails } from '../../../data/slices/quote-details-slice';
import useTranslations from '../../../hooks/use-translations';

export default function MarqueeCard({ marquee, setInfoWindow }) {
    // Translations
    const eventFormTranslations = useTranslations('EVENT_FORM');
    const globalTranslations = useTranslations('GLOBAL');

    const quoteDetails = useSelector((store) => store.quoteDetails);
    const quoteDetailsDispatch = useDispatch();
    const isSelected = quoteDetails?.MARQUEE?.id === marquee?.id;

    const handleMarqueeSelection = useCallback(() => {
        quoteDetailsDispatch(
            setQuoteDetails({
                MARQUEE: marquee,
                OPTION_COUNT: quoteDetails?.OPTION_COUNT || 1
            })
        );
    }, [marquee, quoteDetails?.OPTION_COUNT, quoteDetailsDispatch]);

    const handleMoreInfo = (e) => {
        e.stopPropagation();

        setInfoWindow(marquee.info);
    };

    return (
        <div
            className={`marquee-card ${
                isSelected ? 'marquee-card--selected' : ''
            }`}
            onClick={handleMarqueeSelection}
            role="button"
        >
            <h3 className="marquee-card__name">
                {marquee.name}
                {marquee.info && (
                    <button
                        className="marquee-card__more-info"
                        onClick={handleMoreInfo}
                    >
                        i
                    </button>
                )}
            </h3>

            <p className="marquee-card__capacity-info">
                <span>
                    {marquee.seated} {eventFormTranslations?.PARTY_SEATED}
                </span>
                <span>
                    {marquee.standing} {eventFormTranslations?.PARTY_STANDING}
                </span>
            </p>

            <div className="marquee-card__select-button">
                {isSelected ? (
                    <SvgIcon
                        name="tick"
                        cssClass="marquee-card__select-button-icon"
                    />
                ) : (
                    <p className="marquee-card__select-button-text">
                        {globalTranslations?.SELECT}
                    </p>
                )}
            </div>
        </div>
    );
}
