// React / Redux
import React from 'react';

// Components JSX
import { useQuoteToolContext } from '../quote-tool';
import StepOne from './step-one/step-one';
import StepTwo from './step-two/step-two';
import StepThree from './step-three/step-three';
import StepFour from './step-four/step-four';
import StepFive from './step-five/step-five';

// Quote Tool Context

// Context

const StepComponents = {
    1: StepOne,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive
};

export default function Steps() {
    const { currentStep } = useQuoteToolContext();

    const Step = StepComponents[currentStep];

    return <Step />;
}
