// React
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useQuoteToolContext } from '../../../../quote-tool';
import useTranslations from '../../../../hooks/use-translations';
import useObjectToArray from '../../../../hooks/use-object-to-array';
import useDate from '../../../../hooks/use-date';

const EventForm = ({ setEventDetailsCompleted, formData, setFormData }) => {
    const {
        quoteToolConfig: { party_types }
    } = useQuoteToolContext();

    const translations = useTranslations('EVENT_FORM');
    const quoteDetails = useSelector((store) => store.quoteDetails);
    const partyTypes = useObjectToArray(party_types);
    const todaysDate = useDate();
    const maxDate = useDate(20);

    const [showDateError, setShowDateError] = useState(false);

    const { PARTY_TYPE, PARTY_DATE, PARTY_GUESTS, PARTY_SEAT_STAND } =
        quoteDetails?.EVENT_DETAILS;

    // Refs for input elements
    const partyTypeRef = useRef(null);
    const partyDateRef = useRef(null);
    const partyGuestsRef = useRef(null);
    const partyStandRef = useRef(null);
    const partySeatRef = useRef(null);

    const handleFormChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === 'PARTY_DATE') {
                const inputDate = moment(value, 'YYYY-MM-DD', true);

                if (
                    !inputDate.isValid() ||
                    inputDate.isBefore(todaysDate, 'day')
                ) {
                    return setShowDateError(true);
                }

                setShowDateError(false);
            }

            return setFormData((prevData) => ({
                ...prevData,
                [name]: name === 'PARTY_TYPE' ? JSON.parse(value) : value
            }));
        },
        [setFormData, todaysDate]
    );

    useEffect(() => {
        const { PARTY_TYPE, PARTY_DATE, PARTY_GUESTS, PARTY_SEAT_STAND } =
            formData;

        const isFormComplete =
            PARTY_TYPE !== '' &&
            PARTY_DATE !== '' &&
            PARTY_GUESTS !== '' &&
            PARTY_SEAT_STAND !== '';

        setEventDetailsCompleted(!showDateError && isFormComplete);
    }, [formData, setEventDetailsCompleted, showDateError]);

    return (
        <form className="event-form" onChange={handleFormChange}>
            {partyTypes && (
                <div className="event-form__field">
                    <p className="event-form__field-title">
                        {translations?.PARTY_TYPE}
                    </p>

                    <div className="event-form__field-input event-form__field-input--type">
                        <select
                            ref={partyTypeRef}
                            name="PARTY_TYPE"
                            value={partyTypeRef?.current?.value || PARTY_TYPE}
                            readOnly={true}
                        >
                            <option value="" disabled readOnly={true}>
                                {translations?.PARTY_TYPE_DEFAULT}
                            </option>

                            {partyTypes?.map(
                                ({ party_type, party_type_id }) => (
                                    <option
                                        key={party_type_id}
                                        value={JSON.stringify({
                                            party_type,
                                            party_type_id
                                        })}
                                    >
                                        {party_type}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                </div>
            )}

            <div className="event-form__field-group">
                <div
                    className={`event-form__field ${
                        showDateError ? 'event-form__field--error' : ''
                    }`}
                >
                    <p className="event-form__field-title">
                        {translations?.PARTY_DATE}
                    </p>

                    <div className="event-form__field-input event-form__field-input--date">
                        <input
                            ref={partyDateRef}
                            max={maxDate}
                            min={todaysDate}
                            name="PARTY_DATE"
                            type="date"
                            maxLength={10}
                            defaultValue={PARTY_DATE}
                            required
                        />
                    </div>
                </div>

                <div className="event-form__field">
                    <p className="event-form__field-title">
                        {translations?.PARTY_GUESTS}
                    </p>

                    <div className="event-form__field-input event-form__field-input--guests">
                        <input
                            ref={partyGuestsRef}
                            max="10000"
                            min="1"
                            name="PARTY_GUESTS"
                            step="1"
                            type="number"
                            pattern="[0-9]*"
                            defaultValue={PARTY_GUESTS}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="event-form__field-radio">
                <p className="event-form__field-title">
                    {translations?.PARTY_SEAT_STAND}
                </p>

                <input
                    ref={partySeatRef}
                    type="radio"
                    name="PARTY_SEAT_STAND"
                    id="seated"
                    value="SEATED"
                    defaultChecked={PARTY_SEAT_STAND === 'SEATED'}
                    required
                />

                <input
                    ref={partyStandRef}
                    type="radio"
                    name="PARTY_SEAT_STAND"
                    id="standing"
                    value="STANDING"
                    defaultChecked={PARTY_SEAT_STAND === 'STANDING'}
                    required
                />

                <div className="event-form__field-radio-labels">
                    <label className="event-form__field-input" htmlFor="seated">
                        {translations?.PARTY_SEATED}
                    </label>
                    <label
                        className="event-form__field-input"
                        htmlFor="standing"
                    >
                        {translations?.PARTY_STANDING}
                    </label>

                    <span className="event-form__field-radio-switcher"></span>
                </div>
            </div>

            {showDateError && (
                <div className="event-form__field-error event-form__field-error--date">
                    Please select a valid date that is not before today.
                </div>
            )}
        </form>
    );
};

export default EventForm;
