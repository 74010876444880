import moment from 'moment';
import objectToArray from './object-to-array';
import { isDateInRange } from './is-date-in-range';

export const getMinHireValue = (partyDate, monthValues, surgeValues) => {
    const partyMonth = moment(partyDate).format('MMMM');

    const monthFound = objectToArray(monthValues).find(
        (month) => month.month === partyMonth
    );

    const minHire = parseFloat(monthFound?.min_hire_value);

    const surges = objectToArray(surgeValues);
    const surgeForMonth = surges?.find((range) =>
        isDateInRange(partyDate, range.date_range)
    );

    if (surgeForMonth) {
        const { decrease, surge_percentage } = surgeForMonth;
        const isDecrease = decrease === 'true';

        if (isDecrease) {
            return minHire - (minHire * parseFloat(surge_percentage)) / 100;
        } else {
            return minHire + (minHire * parseFloat(surge_percentage)) / 100;
        }
    }

    return minHire;
};
