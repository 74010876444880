// React / Redux
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetToolConfigQuery } from './data/api/jet-engine-api';

// Components JSX
import Loader from './common/loader/loader';
import Steps from './modules/steps';
import Navigation from './common/navigation/navigation';

// Components CSS
import './styles/main.scss';
import './quote-tool.css';
import { useDispatch } from 'react-redux';
import { setApiKey } from './data/slices/auth-slice';
import { setQuoteDetails } from './data/slices/quote-details-slice';

const QuoteToolContext = createContext();
export const useQuoteToolContext = () => useContext(QuoteToolContext);

export default function QuoteTool() {
    const dispatch = useDispatch();

    const [currentStep, setCurrentStep] = useState(1);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [basketOpen, setBasketOpen] = useState(false);
    const [optionPhase, setOptionPhase] = useState(0);

    const { data: quoteToolConfig, isLoading: quoteToolConfigLoading } =
        useGetToolConfigQuery();

    useEffect(() => {
        const href = window.location.href;
        const url = new URL(href);
        const params = new URLSearchParams(url.search);
        const utmSource = params.get('utm_source');

        if (utmSource) {
            dispatch(setQuoteDetails({ UTM_SOURCE: utmSource }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (quoteToolConfig?.wordpress_quotes_api_key) {
            dispatch(setApiKey(quoteToolConfig?.wordpress_quotes_api_key));
        }
    }, [dispatch, quoteToolConfig?.wordpress_quotes_api_key]);

    useEffect(() => {
        if (currentStep) {
            setBasketOpen(false);
        }
    }, [currentStep]);

    return (
        <div className="quote-tool">
            {quoteToolConfigLoading ? (
                <Loader />
            ) : (
                <QuoteToolContext.Provider
                    value={{
                        currentStep,
                        setCurrentStep,
                        optionPhase,
                        setOptionPhase,
                        quoteToolConfig,
                        setNextDisabled,
                        basketOpen,
                        setBasketOpen
                    }}
                >
                    <Steps />
                    {currentStep > 1 && currentStep < 4 && (
                        <Navigation nextDisabled={nextDisabled} />
                    )}
                </QuoteToolContext.Provider>
            )}
        </div>
    );
}
