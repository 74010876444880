// React
import React from 'react';
import SvgIcon from '../../../../common/svg-icon';

const PostcodeResults = ({ results, resultSelected, onSelect }) => {
    return (
        (resultSelected || results) && (
            <ul className="postcode-results">
                {resultSelected && (
                    <li
                        className="postcode-results__result postcode-results__result--selected"
                        tabIndex={0}
                    >
                        <span>Selected:</span>
                        {resultSelected.description}
                    </li>
                )}

                {results?.map((result) => (
                    <li
                        key={result.place_id}
                        className="postcode-results__result"
                        onClick={({ target }) => onSelect(target, result)}
                        onKeyDown={({ key, target }) =>
                            key === 'Enter' && onSelect(target, result)
                        }
                        tabIndex={0}
                    >
                        {result.description}

                        {(resultSelected?.place_id === result?.place_id ||
                            result.selected) && (
                            <SvgIcon
                                name="tick"
                                cssClass="postcode-results__result-icon"
                            />
                        )}
                    </li>
                ))}
            </ul>
        )
    );
};

export default PostcodeResults;
