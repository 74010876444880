export const TRANSLATIONS = {
    GLOBAL: {
        SELECT: 'Select',
        SELECTED: 'Selected',
        EACH: 'each',
        ADD_LINEN: 'Add Linen Cloth?'
    },
    STEP_ONE: {
        AREA_COVERED: 'We Cover Your Area!',
        CONFIRM: 'Confirm',
        TRY_AGAIN: 'Back To Start'
    },
    STEP_TWO: {},
    STEP_THREE: {
        ROOFING: 'Floor & Roofing',
        LIGHTING_HEATING: 'Lighting & Heating',
        FURNITURE: 'Furniture',
        PARTY: 'Party'
    },
    STEP_FOUR: {
        FIRST_NAME: 'First Name:',
        LAST_NAME: 'Last Name:',
        EMAIL: 'Email:',
        MOBILE_NUMBER: 'Mobile Number:',
        EVENT_DATE: 'Event Date:',
        GET_QUOTE: 'Get Quote!'
    },
    EVENT_FORM: {
        PARTY_TYPE: 'Type Of Party',
        PARTY_TYPE_DEFAULT: 'Choose type',
        PARTY_DATE: 'Party Date',
        PARTY_GUESTS: 'Party Guests',
        PARTY_SEAT_STAND: 'Are your guests seated or standing?',
        PARTY_SEATED: 'Seated',
        PARTY_STANDING: 'Standing'
    }
};
