// React
import React from 'react';

const SvgIcon = ({ name, cssClass }) => {
    return (
        <img
            className={cssClass}
            src={`${process.env.REACT_APP_ASSETS_PATH}/assets/svgs/${name}.svg`}
            alt={`${name}-icon`}
        />
    );
};

export default SvgIcon;
