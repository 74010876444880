// React
import React from 'react';

// CSS
import './loader.scss';

const Loader = ({ loaderType = 'pulse' }) => {
    return (
        <div className={`loader ${loaderType}`}>
            <img
                src="https://www.partyinyourgarden.co.uk/wp-content/uploads/Party-in-Your-Garden-1-1200x720.png"
                alt="Loader Icon"
            />
        </div>
    );
};

export default Loader;
