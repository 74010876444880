import { useEffect, useState } from 'react';

const useDate = (yearsToAdd) => {
    const [date, setDate] = useState(null);

    useEffect(() => {
        const date = new Date();
        yearsToAdd && date.setFullYear(date.getFullYear() + yearsToAdd);

        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        setDate(`${year}-${month}-${day}`);
    }, [yearsToAdd]);

    return date;
};

export default useDate;
