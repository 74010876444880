import moment from 'moment';
import objectToArray from './object-to-array';
import { isDateInRange } from './is-date-in-range';

export const getMonthPrice = (partyDate, monthValues, surgeValues) => {
    const partyMonth = moment(partyDate).format('MMMM');
    const monthFound = objectToArray(monthValues).find(
        (month) => month.month === partyMonth
    );

    const monthPrice = parseFloat(monthFound?.value);

    const surges = objectToArray(surgeValues);
    const surgeForMonth = surges?.find((range) =>
        isDateInRange(partyDate, range.date_range)
    );

    if (surgeForMonth) {
        const { decrease, surge_percentage } = surgeForMonth;
        const isDecrease = decrease === 'true';

        if (isDecrease) {
            return (
                monthPrice - (monthPrice * parseFloat(surge_percentage)) / 100
            );
        } else {
            return (
                monthPrice + (monthPrice * parseFloat(surge_percentage)) / 100
            );
        }
    }

    return monthPrice;
};
