// React / Redux
import React from 'react';

// Components JSX

// Quote Tool Context
import { useQuoteToolContext } from '../../quote-tool';
import { useSelector } from 'react-redux';
import SvgIcon from '../svg-icon';

export default function Basket({ cssClass }) {
    const { basketOpen, setBasketOpen, setCurrentStep } = useQuoteToolContext();
    const quoteDetails = useSelector((store) => store.quoteDetails);

    return (
        quoteDetails && (
            <>
                <button
                    className={`basket__button ${cssClass}-button`}
                    onClick={() => setBasketOpen(!basketOpen)}
                >
                    {basketOpen ? (
                        <SvgIcon name="close-white" />
                    ) : (
                        <>
                            <SvgIcon name="shopping-bag" />
                            <div className="basket__button-count">
                                {quoteDetails?.OPTION_COUNT}
                            </div>
                        </>
                    )}
                </button>

                {basketOpen && (
                    <div className={`basket__details ${cssClass}-details`}>
                        <h3 className="basket__details-header">
                            Event Details
                        </h3>
                        <button
                            className="basket__event-details"
                            onClick={() => setCurrentStep(1)}
                        >
                            <p>{quoteDetails.ADDRESS?.description}</p>
                            <p>
                                {quoteDetails.EVENT_DETAILS?.PARTY_TYPE?.party_type?.toLowerCase()}
                                {' | '}
                                {quoteDetails.EVENT_DETAILS?.PARTY_GUESTS}{' '}
                                {quoteDetails.EVENT_DETAILS?.PARTY_SEAT_STAND?.toLowerCase()}
                            </p>

                            <div className="basket__details-item-edit">
                                edit
                            </div>
                        </button>

                        <div className="basket__details-list">
                            {quoteDetails.MARQUEE?.name && (
                                <>
                                    <div className="basket__details-item-header">
                                        <p className="basket__details-title">
                                            Marquee:
                                        </p>
                                        <button
                                            className="basket__details-item-edit"
                                            onClick={() => setCurrentStep(2)}
                                        >
                                            edit
                                        </button>
                                    </div>

                                    <p className="basket__details-item">
                                        <span>
                                            {quoteDetails.MARQUEE?.name}
                                        </span>
                                    </p>
                                </>
                            )}

                            {quoteDetails.MARQUEE_OPTIONS?.length > 0 && (
                                <>
                                    <div className="basket__details-item-header">
                                        <p className="basket__details-title">
                                            Options:
                                        </p>
                                        <button
                                            className="basket__details-item-edit"
                                            onClick={() => setCurrentStep(3)}
                                        >
                                            edit
                                        </button>
                                    </div>

                                    {quoteDetails.MARQUEE_OPTIONS?.map(
                                        (option) => (
                                            <div
                                                className="basket__details-item"
                                                key={option.option_id}
                                            >
                                                <span className="basket__details-item-name">
                                                    {option.option_name}
                                                    {option.addLinenCloth && (
                                                        <span>
                                                            {' '}
                                                            + Linen Cloth
                                                        </span>
                                                    )}
                                                </span>

                                                <div>
                                                    {option.qty ? (
                                                        <span className="basket__details-item-qty">
                                                            Quantity:{' '}
                                                            {option.qty}
                                                        </span>
                                                    ) : null}

                                                    <span className="basket__details-item-price">
                                                        £{option.option_price}{' '}
                                                        Per Item
                                                        {option.addLinenCloth && (
                                                            <span>{` + £${option.option_linen_cloth_price} Per Cloth`}</span>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    );
}
