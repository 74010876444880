import { useEffect, useState } from 'react';

// Array Helper
export const useObjectToArray = (obj) => {
    const [array, setArray] = useState(null);

    useEffect(() => {
        setArray(Object.keys(obj));
    }, [obj, setArray]);

    return array?.map((key, index) => ({
        ...obj[key]
    }));
};

export default useObjectToArray;
