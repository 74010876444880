import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_CONFIG } from './base-api-config';
import objectToArray from '../../common/helpers/object-to-array';

export const jetEngineApi = createApi({
    reducerPath: 'jetEngineApi',
    baseQuery: fetchBaseQuery({
        method: 'GET',
        baseUrl: API_CONFIG.WORD_PRESS_JSON
    }),
    endpoints: (builder) => ({
        getToolConfig: builder.query({
            query: () => `${API_CONFIG.JET_CCT_API}/quote_tool_v2`,
            transformResponse: (data) => data?.[0]
        }),
        getMarquees: builder.query({
            query: () => `${API_CONFIG.JET_CCT_API}/marquees`
        }),
        getMarqueeById: builder.query({
            query: (id) => `${API_CONFIG.JET_CCT_API}/marquees/${id}`
        }),
        getInteriorOptions: builder.query({
            query: () => `${API_CONFIG.JET_CCT_API}/marquee_interior_opt`,
            transformResponse: ([response]) => {
                const {
                    lighting_heating_options,
                    furniture_options,
                    party_options
                } = response;

                return (
                    response && [
                        objectToArray(lighting_heating_options),
                        objectToArray(furniture_options),
                        objectToArray(party_options)
                    ]
                );
            }
        }),
        postQuotePage: builder.mutation({
            query: ({ title, content, author, source }) => ({
                url: API_CONFIG.JET_QUOTES_API,
                method: 'POST',
                body: {
                    title,
                    content,
                    jet_quote_author: author,
                    jet_quote_source: source
                }
            })
        })
    })
});

export const {
    useGetMarqueesQuery,
    useGetMarqueeByIdQuery,
    useGetToolConfigQuery,
    useGetInteriorOptionsQuery,
    usePostQuotePageMutation
} = jetEngineApi;
