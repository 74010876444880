import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    EVENT_DETAILS: {
        PARTY_TYPE: '',
        PARTY_DATE: '',
        PARTY_GUESTS: '',
        PARTY_SEAT_STAND: ''
    }
};

export const quoteDetailsSlice = createSlice({
    name: 'quoteDetails',
    initialState,
    reducers: {
        // Set quote details data
        setQuoteDetails: (state, action) => {
            return (state = { ...state, ...action.payload });
        }
    }
});

export const { setQuoteDetails } = quoteDetailsSlice.actions;

export default quoteDetailsSlice.reducer;
