// React / Redux
import React from 'react';

// Quote Tool Context
import { useQuoteToolContext } from '../../quote-tool';

export default function Navigation({ nextDisabled }) {
    const { currentStep, setCurrentStep, optionPhase, setOptionPhase } =
        useQuoteToolContext();

    return (
        <div className="navigation">
            <button
                className="navigation__next"
                onClick={() =>
                    currentStep === 3 && optionPhase < 3
                        ? setOptionPhase((prevPhase) => prevPhase + 1)
                        : setCurrentStep((prevStep) => prevStep + 1)
                }
                disabled={nextDisabled}
            >
                {currentStep === 3 && optionPhase < 3
                    ? 'Next Option'
                    : 'Next Step'}
            </button>

            <button
                className="navigation__back"
                onClick={() =>
                    currentStep === 3 && optionPhase <= 3 && optionPhase > 0
                        ? setOptionPhase((prevPhase) => prevPhase - 1)
                        : setCurrentStep((prevStep) => prevStep - 1)
                }
            >
                {currentStep === 3 && optionPhase <= 3 && optionPhase > 0
                    ? 'Previous Option'
                    : 'Back'}
            </button>
        </div>
    );
}
