// React
import { useState, useEffect } from 'react';

// Translations
import { TRANSLATIONS } from '../translations';

const useTranslations = (key) => {
    const [translations, setTranslations] = useState(null);

    useEffect(() => {
        // Set translations from imported TRANSLATIONS
        setTranslations(TRANSLATIONS[key]);
    }, [key]);

    return translations;
};

export default useTranslations;
