import React from 'react';

export default function MarqueeOptionsTable({ quoteDetails, depositAmount }) {
    return (
        <div className="quote-options">
            <div className="quote-options__table">
                <div className="quote-options__table-header">
                    <div>Marquee</div>
                </div>

                <div className="quote-options__table-rows">
                    <div className="quote-options__table-row">
                        <div className="quote-options__table-row-item">
                            {quoteDetails.MARQUEE.name}
                        </div>
                        <div className="quote-options__table-row-item quote-options__table-row-item--recommended">
                            £{quoteDetails.MARQUEE.price}
                        </div>
                    </div>

                    <div className="quote-options__table-row">
                        <div className="quote-options__table-row-item">
                            Pleated White Roof Lining
                        </div>
                    </div>
                </div>
            </div>

            {quoteDetails.MARQUEE_OPTIONS?.length ? (
                <div className="quote-options__table">
                    <div className="quote-options__table-rows">
                        <div className="quote-options__table-row quote-options__table-row--header">
                            <div className="quote-options__table-row-item">
                                Options Selected
                            </div>
                            <div className="quote-options__table-row-item">
                                Unit Price
                            </div>
                            <div className="quote-options__table-row-item">
                                Number Required
                            </div>
                            <div className="quote-options__table-row-item">
                                Total Price
                            </div>
                        </div>

                        {quoteDetails.MARQUEE_OPTIONS.map((option, index) => (
                            <div
                                key={index}
                                className="quote-options__table-row"
                            >
                                <div className="quote-options__table-row-item">
                                    {option.option_name}
                                </div>
                                <div className="quote-options__table-row-item">
                                    £
                                    {option.qty
                                        ? parseFloat(
                                              option.option_price
                                          ).toFixed(2)
                                        : Number(option.option_price)}
                                </div>
                                <div className="quote-options__table-row-item">
                                    {option.qty || '1'}
                                </div>
                                <div className="quote-options__table-row-item">
                                    £
                                    {option.qty
                                        ? parseFloat(option.option_price) *
                                          option.qty
                                        : option.option_price}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}

            <div className="quote-options__totals">
                <div className="quote-options__totals-group">
                    {quoteDetails.DELIVERY_COST && (
                        <div className="quote-options__totals-distance-total">
                            <span>Delivery and Installation:</span>
                            <span>£{quoteDetails.DELIVERY_COST}</span>
                        </div>
                    )}

                    <div className="quote-options__totals-sub-total">
                        <span>Sub Total:</span>
                        <span>£{quoteDetails.QUOTE_PRICE.grossTotal}</span>
                    </div>
                    <div className="quote-options__totals-vat">
                        <span>VAT:</span>
                        <span>
                            £
                            {(
                                Number(quoteDetails.QUOTE_PRICE.grossTotal) *
                                0.2
                            ).toFixed(2)}
                        </span>
                    </div>
                    <div className="quote-options__totals-total">
                        <span>Total:</span>
                        <span>£{quoteDetails.QUOTE_PRICE.netTotal}</span>
                    </div>
                </div>

                <div className="quote-options__totals-deposit">
                    <div className="quote-options__totals-deposit-percent">
                        Deposit Payable
                    </div>
                    <div className="quote-options__totals-deposit-total">
                        £{depositAmount}
                    </div>
                </div>
            </div>
        </div>
    );
}
