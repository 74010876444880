import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_CONFIG } from './base-api-config';

export const zapierApi = createApi({
    reducerPath: 'zapierApi',
    baseQuery: fetchBaseQuery({ baseUrl: API_CONFIG.ZAPIER_API }),
    endpoints: (builder) => ({
        postZapierData: builder.mutation({
            query: ({ data, zapId }) => ({
                url: `/${zapId}`,
                method: 'POST',
                headers: {
                    'content-type': 'text/plain;charset=UTF-8'
                },
                body: JSON.stringify(data)
            })
        })
    })
});

export const { usePostZapierDataMutation } = zapierApi;
