export const getOptionsPrice = (options) => {
    const prices = options?.map((option) => {
        const price = parseFloat(option?.option_price);

        if (option.qty) {
            if (option.addLinenCloth) {
                const linenPrice = option.option_linen_cloth_price * option.qty;

                return price * option.qty + linenPrice;
            }

            return price * option.qty;
        }

        return price;
    }) || [0];

    return prices?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
    );
};
