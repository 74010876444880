import moment from 'moment';

export const isDateInRange = (dateString, dateRange) => {
    const [start, end] = dateRange.split(' - ');
    const startDate = moment(start, 'DD/MM/YYYY');
    const endDate = moment(end, 'DD/MM/YYYY');
    const dateToCheck = moment(dateString, 'YYYY-MM-DD');

    return dateToCheck.isBetween(startDate, endDate, null, '[]');
};
