// React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Component JSX
import { useQuoteToolContext } from '../../quote-tool';
import Basket from '../../common/basket/basket';
import moment from 'moment';
import { setQuoteDetails } from '../../data/slices/quote-details-slice';
import useTranslations from '../../hooks/use-translations';

export default function StepFour() {
    const { setCurrentStep } = useQuoteToolContext();
    const quoteDetails = useSelector((store) => store.quoteDetails);
    const quoteDetailsDispatch = useDispatch();

    const translations = useTranslations('STEP_FOUR');

    const [formData, setFormData] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        EMAIL: '',
        MOBILE_NUMBER: ''
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [formError, setFormError] = useState(false);

    const {
        setNextDisabled,
        quoteToolConfig: {
            step_four_heading: stepFourHeading,
            step_four_description: stepFourDescription
        }
    } = useQuoteToolContext();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        setIsFormValid(checkFormPopulated({ ...formData, [name]: value }));
    };

    const checkFormPopulated = (formData) => {
        for (const key in formData) {
            if (formData[key] === '') {
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        quoteDetailsDispatch(
            setQuoteDetails({
                CONTACT_DETAILS: formData
            })
        );

        setCurrentStep(5);
    };

    useEffect(() => {
        setNextDisabled(!isFormValid);

        return () => setNextDisabled(true);
    }, [isFormValid, setNextDisabled]);

    return (
        <div className="step-four">
            <div className="phase phase--header">
                <div className="phase__item">
                    <div className="phase__header">
                        <h2>{stepFourHeading}</h2>
                        <p>{stepFourDescription}</p>
                    </div>

                    <Basket cssClass="step-four__basket" />
                </div>
            </div>

            <div className="phase phase--contact-form">
                <div className="phase__item">
                    <form
                        className={`step-four__form ${
                            formError ? 'step-four__form--submitted' : ''
                        }`}
                        onSubmit={handleSubmit}
                    >
                        <div className="step-four__field">
                            <label
                                className="step-four__field-title"
                                htmlFor="FIRST_NAME"
                            >
                                {translations?.FIRST_NAME}
                            </label>
                            <input
                                className="step-four__field-input"
                                type="text"
                                id="FIRST_NAME"
                                name="FIRST_NAME"
                                value={formData.FIRST_NAME}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="step-four__field">
                            <label htmlFor="LAST_NAME">
                                {translations?.LAST_NAME}
                            </label>
                            <input
                                className="step-four__field-input"
                                type="text"
                                id="LAST_NAME"
                                name="LAST_NAME"
                                value={formData.LAST_NAME}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="step-four__field">
                            <label htmlFor="EMAIL">{translations?.EMAIL}</label>
                            <input
                                className="step-four__field-input"
                                type="email"
                                id="EMAIL"
                                name="EMAIL"
                                value={formData.EMAIL}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="step-four__field">
                            <label htmlFor="MOBILE_NUMBER">
                                {translations?.MOBILE_NUMBER}
                            </label>
                            <input
                                className="step-four__field-input"
                                type="tel"
                                id="MOBILE_NUMBER"
                                name="MOBILE_NUMBER"
                                value={formData.MOBILE_NUMBER}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="step-four__field">
                            <label htmlFor="EVENT_DATE">
                                {translations?.EVENT_DATE}
                            </label>
                            <input
                                className="step-four__field-input"
                                type="text"
                                id="EVENT_DATE"
                                name="EVENT_DATE"
                                value={moment(
                                    quoteDetails?.EVENT_DETAILS?.PARTY_DATE,
                                    'YYYY-MM-DD'
                                ).format('dddd, DD MMMM YYYY')}
                                disabled
                            />
                        </div>
                        <button
                            className="step-four__submit"
                            type="submit"
                            onClick={() => setFormError(true)}
                            disabled={!isFormValid}
                        >
                            {translations?.GET_QUOTE}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
