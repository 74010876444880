// React / Redux
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';

// Components JSX
import { usePostQuoteMutation } from '../../data/api/wordpress-api';
import { useQuoteToolContext } from '../../quote-tool';
import { usePostZapierDataMutation } from '../../data/api/zapier-api';
import MarqueeOptionsTable from './components/marquee-options-table';
import { getOptionsPrice } from '../../common/helpers/get-options-price';
import { getMonthPrice } from '../../common/helpers/get-month-price';

export default function StepFive() {
    const quoteDetails = useSelector((store) => store.quoteDetails);
    const [quotePrice, setQuotePrice] = useState(null);
    const [marqueePrice, setMarqueePrice] = useState(null);
    const [quoteId, setQuoteId] = useState(false);

    const stepFiveContainer = useRef();

    const {
        quoteToolConfig: {
            zapier_id: zapId,
            wordpress_thank_you_page,
            deposit_amount
        }
    } = useQuoteToolContext();

    const [postQuote] = usePostQuoteMutation();
    const [postZapier] = usePostZapierDataMutation();

    const handleStoreQuote = useCallback(
        (quoteData) => {
            const quoteId = quoteDetails?.QUOTE_ID;

            postQuote({
                quote_id: quoteId,
                quote_content: encodeURIComponent(JSON.stringify(quoteData))
            })
                .then(({ data: { id } }) => {
                    setQuoteId(id);

                    stepFiveContainer?.current?.scrollIntoView();

                    // Post to Zapier
                    postZapier({ data: quoteData, zapId })
                        .then(({ data }) => {
                            if (data.status === 'success') {
                                setTimeout(() => {
                                    window.location.href =
                                        wordpress_thank_you_page;
                                }, 1500);
                            }
                        })
                        .catch((error) => {
                            console.error('Error posting quote:', error);
                        });
                })
                .catch((error) => {
                    console.error('Error posting quote:', error);
                });
        },
        [
            postQuote,
            postZapier,
            quoteDetails?.QUOTE_ID,
            wordpress_thank_you_page,
            zapId
        ]
    );

    const calculateQuote = useCallback(() => {
        const {
            EVENT_DETAILS: { PARTY_DATE },
            MARQUEE: { monthly_values, surge_pricing },
            MARQUEE_OPTIONS,
            DELIVERY_COST
        } = quoteDetails;

        const monthPrice = getMonthPrice(
            PARTY_DATE,
            monthly_values,
            surge_pricing
        );

        const optionsPrice = getOptionsPrice(MARQUEE_OPTIONS);

        const totalPrice =
            monthPrice + optionsPrice + parseFloat(DELIVERY_COST);

        setMarqueePrice(monthPrice);

        setQuotePrice({
            grossTotal: totalPrice.toFixed(2),
            netTotal: (totalPrice * 1.2).toFixed(2)
        });
    }, [quoteDetails]);

    useEffect(
        () => quoteDetails && calculateQuote(),
        [calculateQuote, quoteDetails]
    );

    useEffect(() => {
        if (quotePrice && !quoteId) {
            const finalQuoteDetails = {
                ...quoteDetails,
                MARQUEE: {
                    ...quoteDetails.MARQUEE,
                    price: marqueePrice
                },
                QUOTE_PRICE: quotePrice
            };

            handleStoreQuote({
                ...finalQuoteDetails,
                MARQUEE_OPTIONS_HTML: renderToString(
                    <MarqueeOptionsTable
                        depositAmount={deposit_amount}
                        quoteDetails={finalQuoteDetails}
                    />
                )
            });
        }
    }, [
        handleStoreQuote,
        deposit_amount,
        marqueePrice,
        quoteDetails,
        quoteId,
        quotePrice
    ]);

    return (
        <div ref={stepFiveContainer} className="step-five">
            <div className="phase phase--quote">
                <div className="phase__item">
                    <p>Redirecting...</p>
                </div>
            </div>
        </div>
    );
}
