// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    apiKey: '' // Initial state for API key
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setApiKey(state, action) {
            state.apiKey = action.payload; // Update API key in the state
        }
    }
});

export const { setApiKey } = authSlice.actions;

export default authSlice.reducer;
