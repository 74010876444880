// React / Redux
import React, { useEffect, useRef, useState } from 'react';

// Components JSX
import PostcodeSearch from './components/postcode-search/postcode-search';
import OutOfRange from './components/out-of-range/out-of-range';
import EventForm from './components/event-form/event-form';
import PostcodeResults from './components/postcode-results/postcode-results';
import { useDispatch, useSelector } from 'react-redux';
import SvgIcon from '../../common/svg-icon';
import useTranslations from '../../hooks/use-translations';
import { useQuoteToolContext } from '../../quote-tool';
import { setQuoteDetails } from '../../data/slices/quote-details-slice';
import { useGetQuoteByIdQuery } from '../../data/api/wordpress-api';
import Loader from '../../common/loader/loader';

const calculateDistanceByRoad = async (origin, destination) => {
    return new Promise((resolve, reject) => {
        const service = new window.google.maps.DistanceMatrixService();

        const request = {
            origins: [origin],
            destinations: [destination],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.IMPERIAL
        };

        service.getDistanceMatrix(request, (response, status) => {
            if (status === 'OK') {
                const distance =
                    response.rows[0].elements[0].distance.value / 1609.344;
                resolve(distance);
            } else {
                reject(
                    new Error(
                        'Google Distance API: Failed to calculate distance'
                    )
                );
            }
        });
    });
};

const calculateCheapestCost = async (selectedPostcode, basePostcodes) => {
    let minDistance = Infinity;
    let cheapestCost = Infinity;
    let nearestBasePostcode = null;
    let nearestRegion = null;

    for (const [region, details] of Object.entries(basePostcodes)) {
        const { base, costRange } = details;

        const distance = await calculateDistanceByRoad(base, selectedPostcode);
        for (const { range, cost } of costRange) {
            if (distance <= range && cost < cheapestCost) {
                cheapestCost = cost;
                nearestBasePostcode = base;
                nearestRegion = region;
            }
        }

        if (distance < minDistance) {
            minDistance = distance;
        }
    }

    return { nearestRegion, nearestBasePostcode, cheapestCost };
};

const convertBasePostcodes = (basePostcodesObj) => {
    const convertedObj = {};

    for (const key in basePostcodesObj) {
        const item = basePostcodesObj[key];
        const regionName =
            item.region_name.charAt(0).toUpperCase() +
            item.region_name.slice(1).toLowerCase();

        const costRangeStr = item.cost_range.replace(/'/g, '"');
        const costRange = JSON.parse(
            costRangeStr.replace(/range/g, '"range"').replace(/cost/g, '"cost"')
        );

        convertedObj[regionName] = {
            base: item.base,
            costRange: costRange
        };
    }

    return convertedObj;
};

export default function StepOne() {
    // Quote details confg
    const {
        setCurrentStep,
        quoteToolConfig: { step_one_oor_body: outOfRangeBody, base_postcodes }
    } = useQuoteToolContext();

    const existingQuoteId = new URLSearchParams(window.location.search).get(
        'quoteId'
    );

    const basePostcodes = convertBasePostcodes(base_postcodes);

    // Translations
    const translations = useTranslations('STEP_ONE');

    // Quote details store
    const quoteDetails = useSelector((store) => store.quoteDetails);
    const { apiKey } = useSelector((store) => store.auth);
    const quoteDetailsDispatch = useDispatch();

    const postcodeSearchRef = useRef();
    const eventFormRef = useRef();

    const [results, setResults] = useState(null);
    const [formData, setFormData] = useState(quoteDetails?.EVENT_DETAILS);

    const [eventDetailsCompleted, setEventDetailsCompleted] = useState(false);
    const [showEventDetailsForm, setShowEventDetailsForm] = useState(false);
    const [showOutOfRange, setShowOutOfRange] = useState(false);
    const [resultSelected, setResultSelected] = useState(quoteDetails?.ADDRESS);
    const [showRetrieveQuote, setShowRetrieveQuote] = useState(false);
    const [skipGetQuote, setSkipGetQuote] = useState(true);
    const [quoteSearchInput, setQuoteSearchInput] = useState('');

    useEffect(() => {
        if (existingQuoteId) {
            setSkipGetQuote(false);
        }
    }, [existingQuoteId]);

    const {
        data: quote,
        error: quoteError,
        isError: quoteIsError,
        isFetching: quoteIsFetching
    } = useGetQuoteByIdQuery(
        { apiKey, quote_id: existingQuoteId || quoteSearchInput },
        {
            skip: skipGetQuote
        }
    );

    const handleQuoteOnInput = (input) => {
        setSkipGetQuote(true);

        const { value } = input.target;

        return setQuoteSearchInput(value);
    };

    const handleResultSelect = (target, resultSelected) => {
        setResults((prevResults) => {
            return prevResults.map((result) => {
                if (result === resultSelected) {
                    setResultSelected({ ...result, selected: true });
                    return { ...result, selected: true };
                } else {
                    return { ...result, selected: false };
                }
            });
        });
    };

    const handleConfirm = async () => {
        // Check postocde is within one of the ranges
        const postcodeCost = await calculateCheapestCost(
            resultSelected?.structured_formatting?.main_text,
            basePostcodes
        ).catch((error) => {
            console.error('PIYG v2: Error calculating cheapest cost - ', error);
        });

        if (!postcodeCost?.nearestBasePostcode) {
            setShowOutOfRange(true);
        } else if (eventDetailsCompleted && formData) {
            quoteDetailsDispatch(
                setQuoteDetails({
                    EVENT_DETAILS: formData,
                    BASE_POSTCODE: postcodeCost?.nearestBasePostcode,
                    DELIVERY_COST: postcodeCost?.cheapestCost,
                    QUOTE_ID: `PIYG_${quoteDetails?.POSTCODE?.replace(
                        ' ',
                        '-'
                    )}_${Date.now()}`
                })
            );

            setCurrentStep(2);
        } else {
            quoteDetailsDispatch(
                setQuoteDetails({
                    ADDRESS: resultSelected,
                    POSTCODE: resultSelected?.structured_formatting?.main_text
                })
            );

            setShowEventDetailsForm(true);
        }
    };

    const handleTryAgain = () => {
        setShowOutOfRange(false);
        setResultSelected(null);
        setResults(null);
    };

    useEffect(() => {
        if (quote?.content) {
            const url = new URL(window.location.href);

            quoteDetailsDispatch(
                setQuoteDetails(JSON.parse(decodeURIComponent(quote?.content)))
            );

            setShowEventDetailsForm(true);
            setResultSelected(true);
            setEventDetailsCompleted(true);
            setShowRetrieveQuote(false);

            url.searchParams.delete('quoteId');
            window.history.replaceState(null, '', url.toString());
        }
    }, [quote?.content, quote?.id, quoteDetailsDispatch]);

    useEffect(() => {
        if (quoteDetails?.ADDRESS && quoteDetails?.EVENT_DETAILS) {
            setResultSelected(quoteDetails?.ADDRESS);
            setFormData(quoteDetails?.EVENT_DETAILS);
        }
    }, [quoteDetails?.ADDRESS, quoteDetails?.EVENT_DETAILS]);

    return existingQuoteId && quoteIsFetching ? (
        <Loader />
    ) : (
        <div className="step-one">
            <div className="step-one__container">
                {!showOutOfRange && (
                    <div
                        className="phase phase--header"
                        ref={postcodeSearchRef}
                    >
                        <div className="phase__item">
                            {showEventDetailsForm ? (
                                <div className="phase__header">
                                    <h2>{translations?.AREA_COVERED}</h2>
                                    <button
                                        className="step-one__postcode-edit"
                                        onClick={() => {
                                            setShowEventDetailsForm(false);
                                            setEventDetailsCompleted(false);
                                        }}
                                    >
                                        {quoteDetails.ADDRESS?.description}
                                        <span className="step-one__postcode-edit-icon-container">
                                            <SvgIcon
                                                name={'edit'}
                                                cssClass="step-one__postcode-edit-icon"
                                            />
                                        </span>
                                    </button>
                                </div>
                            ) : (
                                <div className="step-one__search">
                                    {showRetrieveQuote ? (
                                        <div className="step-one__retrieve-quote-search">
                                            <p className="step-one__retrieve-quote-search-label">
                                                Enter your Quote ID...
                                            </p>

                                            <div className="step-one__retrieve-quote-search-input-container">
                                                <SvgIcon
                                                    name={'arrow-right'}
                                                    cssClass="step-one__retrieve-quote-search-icon"
                                                />

                                                <input
                                                    className="step-one__retrieve-quote-search-input"
                                                    onChange={
                                                        handleQuoteOnInput
                                                    }
                                                    value={quoteSearchInput}
                                                    tabIndex={0}
                                                />

                                                <button
                                                    className="step-one__retrieve-quote-search-btn"
                                                    disabled={
                                                        quoteSearchInput.length <
                                                        4
                                                    }
                                                    onClick={() =>
                                                        setSkipGetQuote(false)
                                                    }
                                                >
                                                    Retrieve
                                                </button>
                                            </div>

                                            {((quoteError?.data?.code ===
                                                'quote_not_found' &&
                                                !quoteIsFetching) ||
                                                quoteIsError) && (
                                                <p className="step-one__retrieve-quote-search-input-error">
                                                    <span>{`Quote ID: "${quoteSearchInput}" not found. `}</span>
                                                    Please try again.
                                                </p>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <PostcodeSearch
                                                resultSelected={resultSelected}
                                                setResults={setResults}
                                            />

                                            {(resultSelected ||
                                                results?.length > 0) && (
                                                <PostcodeResults
                                                    results={results}
                                                    resultSelected={
                                                        resultSelected
                                                    }
                                                    onSelect={
                                                        handleResultSelect
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {showEventDetailsForm && (
                    <div className="phase phase--event-form" ref={eventFormRef}>
                        <div className="phase__item">
                            <div className="step-one__event-form">
                                <EventForm
                                    setEventDetailsCompleted={
                                        setEventDetailsCompleted
                                    }
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showOutOfRange && (
                    <div className="phase phase--out-of-range">
                        <div className="phase__item">
                            <div className="step-one__out-of-range">
                                <OutOfRange body={outOfRangeBody} />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {showOutOfRange ? (
                <button
                    className="step-one__try-again"
                    onClick={handleTryAgain}
                >
                    {translations?.TRY_AGAIN}
                </button>
            ) : (
                resultSelected &&
                !showRetrieveQuote && (
                    <button
                        className="step-one__confirm"
                        onClick={handleConfirm}
                        disabled={
                            showEventDetailsForm && !eventDetailsCompleted
                        }
                    >
                        {translations?.CONFIRM}
                    </button>
                )
            )}

            {!resultSelected && (
                <div className="step-one__retrieve-quote">
                    <p className="step-one__retrieve-quote-text">
                        {showRetrieveQuote
                            ? 'Start a new quote?'
                            : 'Already have a quote?'}
                    </p>
                    <button
                        className="step-one__retrieve-quote-btn"
                        onClick={() => setShowRetrieveQuote(!showRetrieveQuote)}
                    >
                        {showRetrieveQuote ? 'Get a quote' : 'Retrieve Here'}
                    </button>
                </div>
            )}
        </div>
    );
}
