export const applyOverrides = (originalData, overrideData) => {
    const cleanOverrideData = overrideData.map((option) => {
        const cleanedOption = {};

        for (const [key, value] of Object.entries(option)) {
            if (value !== null && value !== '') {
                cleanedOption[key] = value;
            }
        }
        return cleanedOption;
    });

    const overriddenData = originalData.map((subArray) => {
        return subArray.map((originalOption) => {
            const overrideOption = cleanOverrideData.find(
                (option) => option.option_id === originalOption.option_id
            );

            return overrideOption !== undefined && overrideOption !== null
                ? { ...originalOption, ...overrideOption }
                : originalOption;
        });
    });

    return overriddenData;
};
