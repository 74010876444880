// React
import React from 'react';

const OutOfRange = ({ body }) => {
    return (
        <div className="out-of-range">
            <div
                className="out-of-range__body"
                dangerouslySetInnerHTML={{ __html: body }}
            ></div>
        </div>
    );
};

export default OutOfRange;
