// React / Redux
import React, { useEffect, useMemo, useRef, useState } from 'react';

// Components JSX
import Basket from '../../common/basket/basket';
import SvgIcon from '../../common/svg-icon';
import { useSelector } from 'react-redux';
import {
    useGetInteriorOptionsQuery,
    useGetMarqueeByIdQuery
} from '../../data/api/jet-engine-api';
import Slider from 'react-slick';
import OptionCard from './components/option-card';
import { useQuoteToolContext } from '../../quote-tool';
import objectToArray from '../../common/helpers/object-to-array';
import useTranslations from '../../hooks/use-translations';
import { getMonthPrice } from '../../common/helpers/get-month-price';
import { applyOverrides } from '../../common/helpers/apply-overrides';
import { getMinHireValue } from '../../common/helpers/get-min-hire-value';

// Constants
const DEFAULT_SLICK_TABS_SETTINGS = {
    slidesToShow: 4,
    speed: 500,
    infinite: false,
    swipe: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 1,
                centerPadding: '40px',
                centerMode: true
            }
        }
    ]
};

const DEFAULT_SLICK_OPTIONS_SETTINGS = {
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    dots: true,
    responsive: [
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 1,
                centerMode: false
            }
        }
    ]
};

export default function StepThree() {
    // Translations
    const translations = useTranslations('STEP_THREE');

    const {
        optionPhase,
        setOptionPhase,
        setNextDisabled,
        quoteToolConfig: {
            step_three_heading: stepThreeHeading,
            step_three_description: stepThreeDescription,
            roofing_info_heading,
            roofing_info_description,
            min_hire_error_msg
        }
    } = useQuoteToolContext();

    const [selectedTab, setSelectedTab] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const [infoWindow, setInfoWindow] = useState(null);
    const [gridMode, setGridMode] = useState(true);
    const [reachedLastPhase, setReachedLastPhase] = useState(false);

    const sliderTabRef = useRef(null);
    const sliderOptionsRef = useRef(null);

    const {
        EVENT_DETAILS: { PARTY_DATE },
        DELIVERY_COST,
        MARQUEE: { _ID, monthly_values, surge_pricing },
        MARQUEE_OPTIONS
    } = useSelector((store) => store.quoteDetails);

    const {
        marqueeRoofingOptions,
        marqueeOverrides,
        isLoading: marqueeLoading
    } = useGetMarqueeByIdQuery(_ID, {
        selectFromResult: ({ data }) => ({
            marqueeRoofingOptions: objectToArray(data?.marquee_roof_options),
            marqueeOverrides: objectToArray(data?.option_overrides || [])
        })
    });

    const totalOptionsCost = useMemo(() => {
        const values = MARQUEE_OPTIONS?.map(
            ({
                option_price,
                qty,
                addLinenCloth,
                option_linen_cloth_price
            }) => {
                const optionCost = qty
                    ? parseFloat(option_price) * qty
                    : parseFloat(option_price);

                const linenCost = addLinenCloth
                    ? option_linen_cloth_price * qty
                    : 0;

                return optionCost + linenCost;
            }
        );

        return values?.reduce((acc, value) => acc + value, 0) || 0;
    }, [MARQUEE_OPTIONS]);

    const currentHireValue = useMemo(
        () =>
            getMonthPrice(PARTY_DATE, monthly_values, surge_pricing) +
            DELIVERY_COST,
        [PARTY_DATE, monthly_values, surge_pricing, DELIVERY_COST]
    );

    const minHireValue = useMemo(
        () => getMinHireValue(PARTY_DATE, monthly_values, surge_pricing),
        [PARTY_DATE, monthly_values, surge_pricing]
    );

    const minHireDiff = minHireValue - (currentHireValue + totalOptionsCost);
    const preMeetsMinHire = currentHireValue >= minHireValue;
    const postMeetsMinHire =
        currentHireValue + totalOptionsCost >= minHireValue;

    const { interiorOptionsQuery, isLoading: interiorOptionsLoading } =
        useGetInteriorOptionsQuery(
            {},
            {
                skip: !marqueeOverrides,
                selectFromResult: ({ data }) => ({
                    interiorOptionsQuery:
                        data &&
                        marqueeOverrides &&
                        applyOverrides(data, marqueeOverrides)
                })
            }
        );

    const interiorOptions = interiorOptionsQuery
        ? [...(interiorOptionsQuery || []), marqueeRoofingOptions || []]
        : null;

    useEffect(() => {
        if (optionPhase === 3 && !preMeetsMinHire && !postMeetsMinHire) {
            setNextDisabled(true);
            setReachedLastPhase(true);
        } else if (!marqueeLoading && !interiorOptionsLoading) {
            setNextDisabled(false);
        }
    }, [
        interiorOptionsLoading,
        marqueeLoading,
        optionPhase,
        postMeetsMinHire,
        preMeetsMinHire,
        setNextDisabled
    ]);

    const slickTabSettings = useMemo(
        () => ({
            ...DEFAULT_SLICK_TABS_SETTINGS,
            initialSlide: selectedTab,
            afterChange: (slide) => {
                setActiveSlide(0);
            }
        }),
        [selectedTab]
    );

    const slickOptionsSettings = useMemo(
        () => ({
            ...DEFAULT_SLICK_OPTIONS_SETTINGS,
            afterChange: (slide) => {
                setActiveSlide(slide);
            }
        }),
        []
    );

    useEffect(() => {
        setSelectedTab(optionPhase);
        setActiveSlide(0);
        setInfoWindow(null);
    }, [optionPhase]);

    useEffect(() => {
        const sliderElement = sliderOptionsRef?.current;

        setTimeout(() => sliderElement?.slickGoTo(activeSlide), 500);
        setInfoWindow(null);
    }, [activeSlide]);

    useEffect(() => {
        const sliderElement = sliderTabRef?.current;

        setTimeout(() => sliderElement?.slickGoTo(selectedTab), 250);
    }, [selectedTab]);

    return (
        <div className="step-three">
            <div className="phase phase--header">
                <div className="phase__item">
                    <div className="phase__header">
                        <h2>{stepThreeHeading}</h2>
                        <p>{stepThreeDescription}</p>
                    </div>

                    <Basket cssClass="step-three__basket" />
                </div>
            </div>

            <div className="phase phase--interior-options">
                <div className="phase__item">
                    <div className="step-three__options-list">
                        <div className="step-three__options-tabs">
                            <Slider ref={sliderTabRef} {...slickTabSettings}>
                                <div className="slider-item">
                                    <button
                                        className={`step-three__option-tab ${
                                            selectedTab === 0
                                                ? 'step-three__option-tab--selected'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSelectedTab(0);
                                            setOptionPhase(0);
                                            setActiveSlide(0);
                                            setInfoWindow(null);
                                        }}
                                    >
                                        {translations?.LIGHTING_HEATING}
                                    </button>
                                </div>

                                <div className="slider-item">
                                    <button
                                        className={`step-three__option-tab ${
                                            selectedTab === 1
                                                ? 'step-three__option-tab--selected'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSelectedTab(1);
                                            setOptionPhase(1);
                                            setActiveSlide(0);
                                            setInfoWindow(null);
                                        }}
                                        disabled={optionPhase < 1}
                                    >
                                        {translations?.FURNITURE}
                                    </button>
                                </div>

                                <div className="slider-item">
                                    <button
                                        className={`step-three__option-tab ${
                                            selectedTab === 2
                                                ? 'step-three__option-tab--selected'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSelectedTab(2);
                                            setOptionPhase(2);
                                            setActiveSlide(0);
                                            setInfoWindow(null);
                                        }}
                                        disabled={optionPhase < 2}
                                    >
                                        {translations?.PARTY}
                                    </button>
                                </div>

                                <div className="slider-item">
                                    <button
                                        className={`step-three__option-tab ${
                                            selectedTab === 3
                                                ? 'step-three__option-tab--selected'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSelectedTab(3);
                                            setOptionPhase(3);
                                            setActiveSlide(0);
                                            setInfoWindow(null);
                                        }}
                                        disabled={optionPhase < 3}
                                    >
                                        {translations?.ROOFING}
                                    </button>
                                </div>
                            </Slider>
                        </div>

                        {reachedLastPhase && !postMeetsMinHire && (
                            <div className="step-three__min-hire-error">
                                {min_hire_error_msg?.replace(
                                    '{MIN_VALUE_LEFT}',
                                    minHireDiff.toFixed(2)
                                )}
                            </div>
                        )}

                        {optionPhase === 3 && (
                            <div className="step-three__option-extra-info">
                                <span>{roofing_info_heading}</span>

                                <span>{roofing_info_description}</span>
                            </div>
                        )}

                        <button
                            className="step-three__option-display"
                            onClick={() => {
                                setGridMode(!gridMode);
                                setInfoWindow(null);
                            }}
                        >
                            <SvgIcon name={gridMode ? 'slider' : 'grid'} />
                            {gridMode ? 'Slider View' : 'Grid View'}
                        </button>

                        {infoWindow ? (
                            <div className="step-three__option-info">
                                <button
                                    className="step-three__option-info-close"
                                    onClick={() => setInfoWindow(null)}
                                >
                                    <SvgIcon
                                        name="close"
                                        cssClass="step-three__option-info-close-icon"
                                    />
                                </button>

                                <div
                                    className="step-three__option-info-content"
                                    dangerouslySetInnerHTML={{
                                        __html: infoWindow
                                    }}
                                />
                            </div>
                        ) : (
                            !gridMode && (
                                <div className="step-three__selected-option-img">
                                    {interiorOptions && (
                                        <img
                                            src={
                                                interiorOptions[selectedTab][
                                                    activeSlide
                                                ]?.option_image
                                            }
                                            alt={
                                                interiorOptions[selectedTab][
                                                    activeSlide
                                                ]?.option_name
                                            }
                                        />
                                    )}
                                </div>
                            )
                        )}

                        <div
                            className={`slider-container ${
                                gridMode ? 'slider-container--grid' : ''
                            }`}
                        >
                            {!gridMode &&
                            interiorOptions?.[selectedTab]?.length > 1 ? (
                                <Slider
                                    ref={sliderOptionsRef}
                                    {...slickOptionsSettings}
                                >
                                    {interiorOptions[selectedTab]?.map(
                                        (option, index) => (
                                            <div
                                                key={option.option_id}
                                                className="slider-item"
                                                onClick={() =>
                                                    setActiveSlide(index)
                                                }
                                            >
                                                <OptionCard
                                                    option={option}
                                                    setInfoWindow={
                                                        setInfoWindow
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                </Slider>
                            ) : (
                                interiorOptions?.[selectedTab]?.map(
                                    (option) => (
                                        <div
                                            key={option.option_id}
                                            className="slider-item"
                                        >
                                            <OptionCard
                                                option={option}
                                                setInfoWindow={setInfoWindow}
                                            />
                                            {gridMode && (
                                                <img
                                                    className="step-three__option-image"
                                                    src={option?.option_image}
                                                    alt={option?.option_name}
                                                />
                                            )}
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
