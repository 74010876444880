// React
import React from 'react';
import { createRoot } from 'react-dom/client';

// Store
import { Provider } from 'react-redux';
import { store } from './data/store';

// Components JS
import QuoteTool from './quote-tool';

// Components CSS
import './index.css';

// Version
import packageJson from '../package.json';

// Constants
const CONTAINER = document.getElementById('root');
const ROOT = createRoot(CONTAINER);
const SCRIPT = document.createElement('script');
const API_KEY = 'AIzaSyC9gQ8XeTNWjI4iYq1BxiGl4cxUZqKOqTo';

// Prepare Google API
SCRIPT.src = `https://maps.googleapis.com/maps/api/js?loading=async&key=${API_KEY}&libraries=places,geometry`;
SCRIPT.async = true;
document.body.appendChild(SCRIPT);

window.QUOTE_TOOL_V2_VERSION = packageJson.version;
console.info(
    `%c Quote Tool v2: v${window.QUOTE_TOOL_V2_VERSION} `,
    'background-color: #6d049d; color: #ffffff; border-radius: 4px'
);

// Render
SCRIPT.onload = () => {
    return ROOT.render(
        <React.StrictMode>
            <Provider store={store}>
                <QuoteTool />
            </Provider>
        </React.StrictMode>
    );
};
